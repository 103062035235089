import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class EmployerShiftRelation {
  getEmployerShiftRelationList(
    start_date,
    end_date,
    employer_ids,
    employer_name,
    employer_organisation_id,
    page,
    page_num
  ) {
    return axios.get(
      `/api/admin/employer-shift-relations`,
      {
        params: {
          start_date: start_date,
          end_date: end_date,
          employer_ids: employer_ids,
          employer_name: employer_name,
          employer_organisation_id: employer_organisation_id,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  editEmployerShiftRelation(datas) {
    return axios.put(
      `/api/admin/employer-shift-relations/`,
      {
        datas: datas,
      },
      config
    );
  }
  deleteEmployerShiftRelation(datas) {
    return axios.delete(
      `/api/admin/employer-shift-relations/`,
      {
        data: {
          datas: datas,
        },
      },
      config
    );
  }
}
