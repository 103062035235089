import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class BlackList {
  getBlackList(employer_id, employer_name) {
    return axios.get(
      `/api/admin/black-list`,
      {
        params: {
          employer_id: employer_id,
          employer_name: employer_name,
        },
      },
      config
    );
  }
  getBlackListDetail(employer_id) {
    return axios.get(
      `/api/admin/black-list/detail`,
      {
        params: {
          employer_id: employer_id,
        },
      },
      config
    );
  }
  addBlackList(employer_id, reason) {
    return axios.post(
      `/api/admin/black-list`,
      {
        employer_id: employer_id,
        reason: reason,
      },
      config
    );
  }
}
